import { debounce as _debounce } from "lodash";
import { doSearch } from "@/services/searchQueries";

export default {
  data() {
    return {
      options: {
        itemsPerPage: 50,
        sortBy: ["createdAt"],
        sortDesc: [true],
        multiSort: false,
        mustSort: true,
      },
      items: [],
      nextToken: undefined,
      nextNextToken: undefined,
      previousTokens: [],
      serverItemsLength: 0,
      itemsPerPage: [10, 20, 50, 100],
      limit: 50,
      page: 1,
      pageCount: 0,
      totalItems: 0,
      tableLoading: false,
      search: "",
    };
  },
  computed: {
    hasNext() {
      return !!this.nextNextToken;
    },
    hasPrev() {
      return this.previousTokens.length;
    },
  },
  watch: {
    search: {
      handler(n, o) {
        if (n !== o) {
          this.paginateReset();
        }
      },
    },
    options: {
      deep: true,
      handler(n, o) {
        // console.log("new options:", n);
        if (n !== o) {
          if (n.itemsPerPage !== o.itemsPerPage) {
            this.limit = n.itemsPerPage;
            this.paginateReset();
            this.fetchData();
            return;
          }

          if (n.page > o.page) {
            this.paginateNext();
            return;
          } else if (n.page < o.page) {
            this.paginatePrev();
            return;
          }

          if (n.sortBy !== o.sortBy || n.sortDesc !== o.sortDesc) {
            this.paginateReset();
            this.fetchData();
            return;
          }
        }
      },
    },
    nextToken: {
      async handler(n, o) {
        if (n !== o) {
          this.fetchData();
        }
      },
    },
  },

  mounted() {
    this.fetchData();
  },
  methods: {
    fetchData: _debounce(async function () {
      const searchKey = this.search;
      let variables = {
        limit: this.limit,
        filter: this.queryFilter
          ? this.queryFilter
          : {
              email: { exists: true },
            },
        sort: [
          {
            direction: this.options.sortDesc[0] === true ? "desc" : "asc",
            field: this.options.sortBy[0],
          },
        ],
      };
      this.$vuetify.goTo(0);
      this.tableLoading = true;

      // if (resetPagination) {
      //   this.paginateReset();
      // }

      if (!searchKey) {
        this.isSearching = false;
      } else {
        if (this.queryContext === "organizations") {
          // console.log("here in query searchKey", searchKey);
          variables.filter = {
            or: [
              { id: { matchPhrasePrefix: searchKey } },
              { name: { matchPhrasePrefix: searchKey } },
              { phone: { matchPhrasePrefix: searchKey } },
            ],
          };
        } else if (this.queryContext === "advisors") {
          // console.log("here in query searchKey", searchKey);
          variables.filter = {
            // multiMatch: {
            //   query: searchKey,
            //   type: "cross_fields",
            //   operator: "and",
            //   fields: ["firstName", "lastName"],
            // },
            or: [
              { id: { matchPhrasePrefix: searchKey } },
              { email: { matchPhrasePrefix: searchKey } },
              { firstName: { matchPhrasePrefix: searchKey } },
              { lastName: { matchPhrasePrefix: searchKey } },
              { phone: { matchPhrasePrefix: searchKey } },
              { companyName: { matchPhrasePrefix: searchKey } },
            ],
          };
        } else {
          variables.filter = {
            email: {
              exists: true,
            },
            or: [
              { id: { matchPhrasePrefix: searchKey } },
              { email: { matchPhrasePrefix: searchKey } },
              { firstName: { matchPhrasePrefix: searchKey } },
              { lastName: { matchPhrasePrefix: searchKey } },
              { phone: { matchPhrasePrefix: searchKey } },
              { advisorId: { matchPhrasePrefix: searchKey } },
            ],
          };

          if (this.options.sortBy[0] === "advisor") {
            variables.sort.field = "advisorId";
          }
        }

        this.isSearching = true;
      }

      try {
        if (this.nextToken !== null) {
          variables.nextToken = this.nextToken;
        } else {
          return;
        }
        const result = await doSearch(this.queryContext, variables, searchKey);
        // console.log("RESULT", result);
        if (result?.errors) {
          if (this.queryContext === "organizations") {
            const organizationWithIssue =
              result.data.searchOrganizations.items[result.errors[0].path[2]]
                .id;
            alert(
              `There was a problem with prospect ${organizationWithIssue}. Error: ${result.errors[0].message}`
            );
          } else if (this.queryContext === "advisors") {
            const advisorWithIssue =
              result.data.searchAdvisors.items[result.errors[0].path[2]].id;
            alert(
              `There was a problem with prospect ${advisorWithIssue}. Error: ${result.errors[0].message}`
            );
          } else {
            const prospectWithIssue =
              result.data.searchProspects.items[result.errors[0].path[2]].id;
            alert(
              `There was a problem with prospect ${prospectWithIssue}. Error: ${result.errors[0].message}`
            );
          }

          return;
        }

        this.nextNextToken = result?.nextToken;
        this.serverItemsLength = result?.total;

        this.items = result?.items;
      } catch (error) {
        console.log("Error fetching paginated items", error);
      } finally {
        this.tableLoading = false;
      }
    }, 500),
    paginateNext() {
      this.previousTokens = [...this.previousTokens, this.nextToken];
      this.nextToken = this.nextNextToken;
      this.nextNextToken = null;

      this.totalItems += this.items.length;
    },
    paginatePrev() {
      this.nextToken = this.previousTokens.pop();
      this.previousTokens = [...this.previousTokens];
      this.nextNextToken = null;

      this.totalItems -= this.items.length;
    },
    paginateReset() {
      this.nextToken = undefined;
      this.previousTokens = [];
      this.nextNextToken = null;
      this.options.page = 1;
      this.items = [];
    },
  },
};
