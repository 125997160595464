/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onCreatePromo = /* GraphQL */ `
  subscription OnCreatePromo($filter: ModelSubscriptionPromoFilterInput) {
    onCreatePromo(filter: $filter) {
      promoCode
      organizationId
      createdAt
      updatedAt
      description
      active
      duration
      endDate
      product_id
      discount
      price_id
      __typename
    }
  }
`;
export const onUpdatePromo = /* GraphQL */ `
  subscription OnUpdatePromo($filter: ModelSubscriptionPromoFilterInput) {
    onUpdatePromo(filter: $filter) {
      promoCode
      organizationId
      createdAt
      updatedAt
      description
      active
      duration
      endDate
      product_id
      discount
      price_id
      __typename
    }
  }
`;
export const onDeletePromo = /* GraphQL */ `
  subscription OnDeletePromo($filter: ModelSubscriptionPromoFilterInput) {
    onDeletePromo(filter: $filter) {
      promoCode
      organizationId
      createdAt
      updatedAt
      description
      active
      duration
      endDate
      product_id
      discount
      price_id
      __typename
    }
  }
`;
export const onCreateResource = /* GraphQL */ `
  subscription OnCreateResource($filter: ModelSubscriptionResourceFilterInput) {
    onCreateResource(filter: $filter) {
      id
      createdAt
      updatedAt
      organizationId
      displayOrder
      title
      subTitle
      mediaType
      s3Media {
        bucket
        region
        key
        __typename
      }
      mediaThumbnail {
        bucket
        region
        key
        __typename
      }
      urlTarget
      videoId
      organizationsToExclude
      __typename
    }
  }
`;
export const onUpdateResource = /* GraphQL */ `
  subscription OnUpdateResource($filter: ModelSubscriptionResourceFilterInput) {
    onUpdateResource(filter: $filter) {
      id
      createdAt
      updatedAt
      organizationId
      displayOrder
      title
      subTitle
      mediaType
      s3Media {
        bucket
        region
        key
        __typename
      }
      mediaThumbnail {
        bucket
        region
        key
        __typename
      }
      urlTarget
      videoId
      organizationsToExclude
      __typename
    }
  }
`;
export const onDeleteResource = /* GraphQL */ `
  subscription OnDeleteResource($filter: ModelSubscriptionResourceFilterInput) {
    onDeleteResource(filter: $filter) {
      id
      createdAt
      updatedAt
      organizationId
      displayOrder
      title
      subTitle
      mediaType
      s3Media {
        bucket
        region
        key
        __typename
      }
      mediaThumbnail {
        bucket
        region
        key
        __typename
      }
      urlTarget
      videoId
      organizationsToExclude
      __typename
    }
  }
`;
export const onCreateOrganization = /* GraphQL */ `
  subscription OnCreateOrganization(
    $filter: ModelSubscriptionOrganizationFilterInput
  ) {
    onCreateOrganization(filter: $filter) {
      id
      name
      routePathName
      logo {
        bucket
        region
        key
        __typename
      }
      active
      accessCode
      url
      address {
        streetAddress1
        streetAddress2
        city
        state
        postalCode
        __typename
      }
      phone
      createdAt
      updatedAt
      advisors {
        items {
          id
          companyName
          companyUrl
          companyLogo {
            bucket
            region
            key
            __typename
          }
          companyAddress
          address {
            streetAddress1
            streetAddress2
            city
            state
            postalCode
            __typename
          }
          firstName
          lastName
          email
          credentials
          phone
          avatar {
            bucket
            region
            key
            __typename
          }
          createdAt
          updatedAt
          showAdvisorCard
          prospectCriteria
          prospects {
            items {
              id
              type
              sendReport
              firstName
              lastName
              email
              phone
              address {
                streetAddress1
                streetAddress2
                city
                state
                postalCode
                __typename
              }
              advisorId
              advisor {
                id
                companyName
                companyUrl
                companyAddress
                firstName
                lastName
                email
                credentials
                phone
                createdAt
                updatedAt
                showAdvisorCard
                prospectCriteria
                notificationEmails
                disclosure
                intelGroupId
                bypassCrm
                customReportCtaUrl
                videoUrl
                scoreReportTemplateId
                surveyQuestions
                collectContactPostSurvey
                organizationId
                acceptRegistrationTerms
                promo_code
                payment_method
                stripe
                __typename
              }
              advisorNotified
              advisorVanityName
              isAnonymous
              lastScoreStep
              subscribed
              scoreSurveyAnswers
              scoreSurveyComplete
              scoreSurveyResults
              scoreSurveySource
              scoreAgreeTerms
              scoreContactComplete
              createdAt
              updatedAt
              origin
              qualified
              ctaRequests {
                id
                name
                createdAt
                updatedAt
                source
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          vanityNames {
            items {
              id
              createdAt
              vanityName
              advisorId
              advisor {
                id
                companyName
                companyUrl
                companyAddress
                firstName
                lastName
                email
                credentials
                phone
                createdAt
                updatedAt
                showAdvisorCard
                prospectCriteria
                notificationEmails
                disclosure
                intelGroupId
                bypassCrm
                customReportCtaUrl
                videoUrl
                scoreReportTemplateId
                surveyQuestions
                collectContactPostSurvey
                organizationId
                acceptRegistrationTerms
                promo_code
                payment_method
                stripe
                __typename
              }
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          notificationEmails
          disclosure
          intelGroupId
          bypassCrm
          customReportCtaUrl
          videoUrl
          scoreReportTemplateId
          surveyQuestions
          collectContactPostSurvey
          organizationId
          organization {
            id
            name
            routePathName
            logo {
              bucket
              region
              key
              __typename
            }
            active
            accessCode
            url
            address {
              streetAddress1
              streetAddress2
              city
              state
              postalCode
              __typename
            }
            phone
            createdAt
            updatedAt
            advisors {
              items {
                id
                companyName
                companyUrl
                companyAddress
                firstName
                lastName
                email
                credentials
                phone
                createdAt
                updatedAt
                showAdvisorCard
                prospectCriteria
                notificationEmails
                disclosure
                intelGroupId
                bypassCrm
                customReportCtaUrl
                videoUrl
                scoreReportTemplateId
                surveyQuestions
                collectContactPostSurvey
                organizationId
                acceptRegistrationTerms
                promo_code
                payment_method
                stripe
                __typename
              }
              nextToken
              __typename
            }
            resources {
              items {
                id
                createdAt
                updatedAt
                organizationId
                displayOrder
                title
                subTitle
                mediaType
                urlTarget
                videoId
                organizationsToExclude
                __typename
              }
              nextToken
              __typename
            }
            promos {
              items {
                promoCode
                organizationId
                createdAt
                updatedAt
                description
                active
                duration
                endDate
                product_id
                discount
                price_id
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          acceptRegistrationTerms
          promo_code
          payment_method
          stripe
          __typename
        }
        nextToken
        __typename
      }
      resources {
        items {
          id
          createdAt
          updatedAt
          organizationId
          displayOrder
          title
          subTitle
          mediaType
          s3Media {
            bucket
            region
            key
            __typename
          }
          mediaThumbnail {
            bucket
            region
            key
            __typename
          }
          urlTarget
          videoId
          organizationsToExclude
          __typename
        }
        nextToken
        __typename
      }
      promos {
        items {
          promoCode
          organizationId
          createdAt
          updatedAt
          description
          active
          duration
          endDate
          product_id
          discount
          price_id
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const onUpdateOrganization = /* GraphQL */ `
  subscription OnUpdateOrganization(
    $filter: ModelSubscriptionOrganizationFilterInput
  ) {
    onUpdateOrganization(filter: $filter) {
      id
      name
      routePathName
      logo {
        bucket
        region
        key
        __typename
      }
      active
      accessCode
      url
      address {
        streetAddress1
        streetAddress2
        city
        state
        postalCode
        __typename
      }
      phone
      createdAt
      updatedAt
      advisors {
        items {
          id
          companyName
          companyUrl
          companyLogo {
            bucket
            region
            key
            __typename
          }
          companyAddress
          address {
            streetAddress1
            streetAddress2
            city
            state
            postalCode
            __typename
          }
          firstName
          lastName
          email
          credentials
          phone
          avatar {
            bucket
            region
            key
            __typename
          }
          createdAt
          updatedAt
          showAdvisorCard
          prospectCriteria
          prospects {
            items {
              id
              type
              sendReport
              firstName
              lastName
              email
              phone
              address {
                streetAddress1
                streetAddress2
                city
                state
                postalCode
                __typename
              }
              advisorId
              advisor {
                id
                companyName
                companyUrl
                companyAddress
                firstName
                lastName
                email
                credentials
                phone
                createdAt
                updatedAt
                showAdvisorCard
                prospectCriteria
                notificationEmails
                disclosure
                intelGroupId
                bypassCrm
                customReportCtaUrl
                videoUrl
                scoreReportTemplateId
                surveyQuestions
                collectContactPostSurvey
                organizationId
                acceptRegistrationTerms
                promo_code
                payment_method
                stripe
                __typename
              }
              advisorNotified
              advisorVanityName
              isAnonymous
              lastScoreStep
              subscribed
              scoreSurveyAnswers
              scoreSurveyComplete
              scoreSurveyResults
              scoreSurveySource
              scoreAgreeTerms
              scoreContactComplete
              createdAt
              updatedAt
              origin
              qualified
              ctaRequests {
                id
                name
                createdAt
                updatedAt
                source
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          vanityNames {
            items {
              id
              createdAt
              vanityName
              advisorId
              advisor {
                id
                companyName
                companyUrl
                companyAddress
                firstName
                lastName
                email
                credentials
                phone
                createdAt
                updatedAt
                showAdvisorCard
                prospectCriteria
                notificationEmails
                disclosure
                intelGroupId
                bypassCrm
                customReportCtaUrl
                videoUrl
                scoreReportTemplateId
                surveyQuestions
                collectContactPostSurvey
                organizationId
                acceptRegistrationTerms
                promo_code
                payment_method
                stripe
                __typename
              }
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          notificationEmails
          disclosure
          intelGroupId
          bypassCrm
          customReportCtaUrl
          videoUrl
          scoreReportTemplateId
          surveyQuestions
          collectContactPostSurvey
          organizationId
          organization {
            id
            name
            routePathName
            logo {
              bucket
              region
              key
              __typename
            }
            active
            accessCode
            url
            address {
              streetAddress1
              streetAddress2
              city
              state
              postalCode
              __typename
            }
            phone
            createdAt
            updatedAt
            advisors {
              items {
                id
                companyName
                companyUrl
                companyAddress
                firstName
                lastName
                email
                credentials
                phone
                createdAt
                updatedAt
                showAdvisorCard
                prospectCriteria
                notificationEmails
                disclosure
                intelGroupId
                bypassCrm
                customReportCtaUrl
                videoUrl
                scoreReportTemplateId
                surveyQuestions
                collectContactPostSurvey
                organizationId
                acceptRegistrationTerms
                promo_code
                payment_method
                stripe
                __typename
              }
              nextToken
              __typename
            }
            resources {
              items {
                id
                createdAt
                updatedAt
                organizationId
                displayOrder
                title
                subTitle
                mediaType
                urlTarget
                videoId
                organizationsToExclude
                __typename
              }
              nextToken
              __typename
            }
            promos {
              items {
                promoCode
                organizationId
                createdAt
                updatedAt
                description
                active
                duration
                endDate
                product_id
                discount
                price_id
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          acceptRegistrationTerms
          promo_code
          payment_method
          stripe
          __typename
        }
        nextToken
        __typename
      }
      resources {
        items {
          id
          createdAt
          updatedAt
          organizationId
          displayOrder
          title
          subTitle
          mediaType
          s3Media {
            bucket
            region
            key
            __typename
          }
          mediaThumbnail {
            bucket
            region
            key
            __typename
          }
          urlTarget
          videoId
          organizationsToExclude
          __typename
        }
        nextToken
        __typename
      }
      promos {
        items {
          promoCode
          organizationId
          createdAt
          updatedAt
          description
          active
          duration
          endDate
          product_id
          discount
          price_id
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const onDeleteOrganization = /* GraphQL */ `
  subscription OnDeleteOrganization(
    $filter: ModelSubscriptionOrganizationFilterInput
  ) {
    onDeleteOrganization(filter: $filter) {
      id
      name
      routePathName
      logo {
        bucket
        region
        key
        __typename
      }
      active
      accessCode
      url
      address {
        streetAddress1
        streetAddress2
        city
        state
        postalCode
        __typename
      }
      phone
      createdAt
      updatedAt
      advisors {
        items {
          id
          companyName
          companyUrl
          companyLogo {
            bucket
            region
            key
            __typename
          }
          companyAddress
          address {
            streetAddress1
            streetAddress2
            city
            state
            postalCode
            __typename
          }
          firstName
          lastName
          email
          credentials
          phone
          avatar {
            bucket
            region
            key
            __typename
          }
          createdAt
          updatedAt
          showAdvisorCard
          prospectCriteria
          prospects {
            items {
              id
              type
              sendReport
              firstName
              lastName
              email
              phone
              address {
                streetAddress1
                streetAddress2
                city
                state
                postalCode
                __typename
              }
              advisorId
              advisor {
                id
                companyName
                companyUrl
                companyAddress
                firstName
                lastName
                email
                credentials
                phone
                createdAt
                updatedAt
                showAdvisorCard
                prospectCriteria
                notificationEmails
                disclosure
                intelGroupId
                bypassCrm
                customReportCtaUrl
                videoUrl
                scoreReportTemplateId
                surveyQuestions
                collectContactPostSurvey
                organizationId
                acceptRegistrationTerms
                promo_code
                payment_method
                stripe
                __typename
              }
              advisorNotified
              advisorVanityName
              isAnonymous
              lastScoreStep
              subscribed
              scoreSurveyAnswers
              scoreSurveyComplete
              scoreSurveyResults
              scoreSurveySource
              scoreAgreeTerms
              scoreContactComplete
              createdAt
              updatedAt
              origin
              qualified
              ctaRequests {
                id
                name
                createdAt
                updatedAt
                source
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          vanityNames {
            items {
              id
              createdAt
              vanityName
              advisorId
              advisor {
                id
                companyName
                companyUrl
                companyAddress
                firstName
                lastName
                email
                credentials
                phone
                createdAt
                updatedAt
                showAdvisorCard
                prospectCriteria
                notificationEmails
                disclosure
                intelGroupId
                bypassCrm
                customReportCtaUrl
                videoUrl
                scoreReportTemplateId
                surveyQuestions
                collectContactPostSurvey
                organizationId
                acceptRegistrationTerms
                promo_code
                payment_method
                stripe
                __typename
              }
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          notificationEmails
          disclosure
          intelGroupId
          bypassCrm
          customReportCtaUrl
          videoUrl
          scoreReportTemplateId
          surveyQuestions
          collectContactPostSurvey
          organizationId
          organization {
            id
            name
            routePathName
            logo {
              bucket
              region
              key
              __typename
            }
            active
            accessCode
            url
            address {
              streetAddress1
              streetAddress2
              city
              state
              postalCode
              __typename
            }
            phone
            createdAt
            updatedAt
            advisors {
              items {
                id
                companyName
                companyUrl
                companyAddress
                firstName
                lastName
                email
                credentials
                phone
                createdAt
                updatedAt
                showAdvisorCard
                prospectCriteria
                notificationEmails
                disclosure
                intelGroupId
                bypassCrm
                customReportCtaUrl
                videoUrl
                scoreReportTemplateId
                surveyQuestions
                collectContactPostSurvey
                organizationId
                acceptRegistrationTerms
                promo_code
                payment_method
                stripe
                __typename
              }
              nextToken
              __typename
            }
            resources {
              items {
                id
                createdAt
                updatedAt
                organizationId
                displayOrder
                title
                subTitle
                mediaType
                urlTarget
                videoId
                organizationsToExclude
                __typename
              }
              nextToken
              __typename
            }
            promos {
              items {
                promoCode
                organizationId
                createdAt
                updatedAt
                description
                active
                duration
                endDate
                product_id
                discount
                price_id
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          acceptRegistrationTerms
          promo_code
          payment_method
          stripe
          __typename
        }
        nextToken
        __typename
      }
      resources {
        items {
          id
          createdAt
          updatedAt
          organizationId
          displayOrder
          title
          subTitle
          mediaType
          s3Media {
            bucket
            region
            key
            __typename
          }
          mediaThumbnail {
            bucket
            region
            key
            __typename
          }
          urlTarget
          videoId
          organizationsToExclude
          __typename
        }
        nextToken
        __typename
      }
      promos {
        items {
          promoCode
          organizationId
          createdAt
          updatedAt
          description
          active
          duration
          endDate
          product_id
          discount
          price_id
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const onCreateAdvisor = /* GraphQL */ `
  subscription OnCreateAdvisor(
    $filter: ModelSubscriptionAdvisorFilterInput
    $id: String
  ) {
    onCreateAdvisor(filter: $filter, id: $id) {
      id
      companyName
      companyUrl
      companyLogo {
        bucket
        region
        key
        __typename
      }
      companyAddress
      address {
        streetAddress1
        streetAddress2
        city
        state
        postalCode
        __typename
      }
      firstName
      lastName
      email
      credentials
      phone
      avatar {
        bucket
        region
        key
        __typename
      }
      createdAt
      updatedAt
      showAdvisorCard
      prospectCriteria
      prospects {
        items {
          id
          type
          sendReport
          firstName
          lastName
          email
          phone
          address {
            streetAddress1
            streetAddress2
            city
            state
            postalCode
            __typename
          }
          advisorId
          advisor {
            id
            companyName
            companyUrl
            companyLogo {
              bucket
              region
              key
              __typename
            }
            companyAddress
            address {
              streetAddress1
              streetAddress2
              city
              state
              postalCode
              __typename
            }
            firstName
            lastName
            email
            credentials
            phone
            avatar {
              bucket
              region
              key
              __typename
            }
            createdAt
            updatedAt
            showAdvisorCard
            prospectCriteria
            prospects {
              items {
                id
                type
                sendReport
                firstName
                lastName
                email
                phone
                advisorId
                advisorNotified
                advisorVanityName
                isAnonymous
                lastScoreStep
                subscribed
                scoreSurveyAnswers
                scoreSurveyComplete
                scoreSurveyResults
                scoreSurveySource
                scoreAgreeTerms
                scoreContactComplete
                createdAt
                updatedAt
                origin
                qualified
                __typename
              }
              nextToken
              __typename
            }
            vanityNames {
              items {
                id
                createdAt
                vanityName
                advisorId
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            notificationEmails
            disclosure
            intelGroupId
            bypassCrm
            customReportCtaUrl
            videoUrl
            scoreReportTemplateId
            surveyQuestions
            collectContactPostSurvey
            organizationId
            organization {
              id
              name
              routePathName
              logo {
                bucket
                region
                key
                __typename
              }
              active
              accessCode
              url
              address {
                streetAddress1
                streetAddress2
                city
                state
                postalCode
                __typename
              }
              phone
              createdAt
              updatedAt
              advisors {
                nextToken
                __typename
              }
              resources {
                nextToken
                __typename
              }
              promos {
                nextToken
                __typename
              }
              __typename
            }
            acceptRegistrationTerms
            promo_code
            payment_method
            stripe
            __typename
          }
          advisorNotified
          advisorVanityName
          isAnonymous
          lastScoreStep
          subscribed
          scoreSurveyAnswers
          scoreSurveyComplete
          scoreSurveyResults
          scoreSurveySource
          scoreAgreeTerms
          scoreContactComplete
          createdAt
          updatedAt
          origin
          qualified
          ctaRequests {
            id
            name
            createdAt
            updatedAt
            source
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      vanityNames {
        items {
          id
          createdAt
          vanityName
          advisorId
          advisor {
            id
            companyName
            companyUrl
            companyLogo {
              bucket
              region
              key
              __typename
            }
            companyAddress
            address {
              streetAddress1
              streetAddress2
              city
              state
              postalCode
              __typename
            }
            firstName
            lastName
            email
            credentials
            phone
            avatar {
              bucket
              region
              key
              __typename
            }
            createdAt
            updatedAt
            showAdvisorCard
            prospectCriteria
            prospects {
              items {
                id
                type
                sendReport
                firstName
                lastName
                email
                phone
                advisorId
                advisorNotified
                advisorVanityName
                isAnonymous
                lastScoreStep
                subscribed
                scoreSurveyAnswers
                scoreSurveyComplete
                scoreSurveyResults
                scoreSurveySource
                scoreAgreeTerms
                scoreContactComplete
                createdAt
                updatedAt
                origin
                qualified
                __typename
              }
              nextToken
              __typename
            }
            vanityNames {
              items {
                id
                createdAt
                vanityName
                advisorId
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            notificationEmails
            disclosure
            intelGroupId
            bypassCrm
            customReportCtaUrl
            videoUrl
            scoreReportTemplateId
            surveyQuestions
            collectContactPostSurvey
            organizationId
            organization {
              id
              name
              routePathName
              logo {
                bucket
                region
                key
                __typename
              }
              active
              accessCode
              url
              address {
                streetAddress1
                streetAddress2
                city
                state
                postalCode
                __typename
              }
              phone
              createdAt
              updatedAt
              advisors {
                nextToken
                __typename
              }
              resources {
                nextToken
                __typename
              }
              promos {
                nextToken
                __typename
              }
              __typename
            }
            acceptRegistrationTerms
            promo_code
            payment_method
            stripe
            __typename
          }
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      notificationEmails
      disclosure
      intelGroupId
      bypassCrm
      customReportCtaUrl
      videoUrl
      scoreReportTemplateId
      surveyQuestions
      collectContactPostSurvey
      organizationId
      organization {
        id
        name
        routePathName
        logo {
          bucket
          region
          key
          __typename
        }
        active
        accessCode
        url
        address {
          streetAddress1
          streetAddress2
          city
          state
          postalCode
          __typename
        }
        phone
        createdAt
        updatedAt
        advisors {
          items {
            id
            companyName
            companyUrl
            companyLogo {
              bucket
              region
              key
              __typename
            }
            companyAddress
            address {
              streetAddress1
              streetAddress2
              city
              state
              postalCode
              __typename
            }
            firstName
            lastName
            email
            credentials
            phone
            avatar {
              bucket
              region
              key
              __typename
            }
            createdAt
            updatedAt
            showAdvisorCard
            prospectCriteria
            prospects {
              items {
                id
                type
                sendReport
                firstName
                lastName
                email
                phone
                advisorId
                advisorNotified
                advisorVanityName
                isAnonymous
                lastScoreStep
                subscribed
                scoreSurveyAnswers
                scoreSurveyComplete
                scoreSurveyResults
                scoreSurveySource
                scoreAgreeTerms
                scoreContactComplete
                createdAt
                updatedAt
                origin
                qualified
                __typename
              }
              nextToken
              __typename
            }
            vanityNames {
              items {
                id
                createdAt
                vanityName
                advisorId
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            notificationEmails
            disclosure
            intelGroupId
            bypassCrm
            customReportCtaUrl
            videoUrl
            scoreReportTemplateId
            surveyQuestions
            collectContactPostSurvey
            organizationId
            organization {
              id
              name
              routePathName
              logo {
                bucket
                region
                key
                __typename
              }
              active
              accessCode
              url
              address {
                streetAddress1
                streetAddress2
                city
                state
                postalCode
                __typename
              }
              phone
              createdAt
              updatedAt
              advisors {
                nextToken
                __typename
              }
              resources {
                nextToken
                __typename
              }
              promos {
                nextToken
                __typename
              }
              __typename
            }
            acceptRegistrationTerms
            promo_code
            payment_method
            stripe
            __typename
          }
          nextToken
          __typename
        }
        resources {
          items {
            id
            createdAt
            updatedAt
            organizationId
            displayOrder
            title
            subTitle
            mediaType
            s3Media {
              bucket
              region
              key
              __typename
            }
            mediaThumbnail {
              bucket
              region
              key
              __typename
            }
            urlTarget
            videoId
            organizationsToExclude
            __typename
          }
          nextToken
          __typename
        }
        promos {
          items {
            promoCode
            organizationId
            createdAt
            updatedAt
            description
            active
            duration
            endDate
            product_id
            discount
            price_id
            __typename
          }
          nextToken
          __typename
        }
        __typename
      }
      acceptRegistrationTerms
      promo_code
      payment_method
      stripe
      __typename
    }
  }
`;
export const onUpdateAdvisor = /* GraphQL */ `
  subscription OnUpdateAdvisor(
    $filter: ModelSubscriptionAdvisorFilterInput
    $id: String
  ) {
    onUpdateAdvisor(filter: $filter, id: $id) {
      id
      companyName
      companyUrl
      companyLogo {
        bucket
        region
        key
        __typename
      }
      companyAddress
      address {
        streetAddress1
        streetAddress2
        city
        state
        postalCode
        __typename
      }
      firstName
      lastName
      email
      credentials
      phone
      avatar {
        bucket
        region
        key
        __typename
      }
      createdAt
      updatedAt
      showAdvisorCard
      prospectCriteria
      prospects {
        items {
          id
          type
          sendReport
          firstName
          lastName
          email
          phone
          address {
            streetAddress1
            streetAddress2
            city
            state
            postalCode
            __typename
          }
          advisorId
          advisor {
            id
            companyName
            companyUrl
            companyLogo {
              bucket
              region
              key
              __typename
            }
            companyAddress
            address {
              streetAddress1
              streetAddress2
              city
              state
              postalCode
              __typename
            }
            firstName
            lastName
            email
            credentials
            phone
            avatar {
              bucket
              region
              key
              __typename
            }
            createdAt
            updatedAt
            showAdvisorCard
            prospectCriteria
            prospects {
              items {
                id
                type
                sendReport
                firstName
                lastName
                email
                phone
                advisorId
                advisorNotified
                advisorVanityName
                isAnonymous
                lastScoreStep
                subscribed
                scoreSurveyAnswers
                scoreSurveyComplete
                scoreSurveyResults
                scoreSurveySource
                scoreAgreeTerms
                scoreContactComplete
                createdAt
                updatedAt
                origin
                qualified
                __typename
              }
              nextToken
              __typename
            }
            vanityNames {
              items {
                id
                createdAt
                vanityName
                advisorId
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            notificationEmails
            disclosure
            intelGroupId
            bypassCrm
            customReportCtaUrl
            videoUrl
            scoreReportTemplateId
            surveyQuestions
            collectContactPostSurvey
            organizationId
            organization {
              id
              name
              routePathName
              logo {
                bucket
                region
                key
                __typename
              }
              active
              accessCode
              url
              address {
                streetAddress1
                streetAddress2
                city
                state
                postalCode
                __typename
              }
              phone
              createdAt
              updatedAt
              advisors {
                nextToken
                __typename
              }
              resources {
                nextToken
                __typename
              }
              promos {
                nextToken
                __typename
              }
              __typename
            }
            acceptRegistrationTerms
            promo_code
            payment_method
            stripe
            __typename
          }
          advisorNotified
          advisorVanityName
          isAnonymous
          lastScoreStep
          subscribed
          scoreSurveyAnswers
          scoreSurveyComplete
          scoreSurveyResults
          scoreSurveySource
          scoreAgreeTerms
          scoreContactComplete
          createdAt
          updatedAt
          origin
          qualified
          ctaRequests {
            id
            name
            createdAt
            updatedAt
            source
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      vanityNames {
        items {
          id
          createdAt
          vanityName
          advisorId
          advisor {
            id
            companyName
            companyUrl
            companyLogo {
              bucket
              region
              key
              __typename
            }
            companyAddress
            address {
              streetAddress1
              streetAddress2
              city
              state
              postalCode
              __typename
            }
            firstName
            lastName
            email
            credentials
            phone
            avatar {
              bucket
              region
              key
              __typename
            }
            createdAt
            updatedAt
            showAdvisorCard
            prospectCriteria
            prospects {
              items {
                id
                type
                sendReport
                firstName
                lastName
                email
                phone
                advisorId
                advisorNotified
                advisorVanityName
                isAnonymous
                lastScoreStep
                subscribed
                scoreSurveyAnswers
                scoreSurveyComplete
                scoreSurveyResults
                scoreSurveySource
                scoreAgreeTerms
                scoreContactComplete
                createdAt
                updatedAt
                origin
                qualified
                __typename
              }
              nextToken
              __typename
            }
            vanityNames {
              items {
                id
                createdAt
                vanityName
                advisorId
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            notificationEmails
            disclosure
            intelGroupId
            bypassCrm
            customReportCtaUrl
            videoUrl
            scoreReportTemplateId
            surveyQuestions
            collectContactPostSurvey
            organizationId
            organization {
              id
              name
              routePathName
              logo {
                bucket
                region
                key
                __typename
              }
              active
              accessCode
              url
              address {
                streetAddress1
                streetAddress2
                city
                state
                postalCode
                __typename
              }
              phone
              createdAt
              updatedAt
              advisors {
                nextToken
                __typename
              }
              resources {
                nextToken
                __typename
              }
              promos {
                nextToken
                __typename
              }
              __typename
            }
            acceptRegistrationTerms
            promo_code
            payment_method
            stripe
            __typename
          }
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      notificationEmails
      disclosure
      intelGroupId
      bypassCrm
      customReportCtaUrl
      videoUrl
      scoreReportTemplateId
      surveyQuestions
      collectContactPostSurvey
      organizationId
      organization {
        id
        name
        routePathName
        logo {
          bucket
          region
          key
          __typename
        }
        active
        accessCode
        url
        address {
          streetAddress1
          streetAddress2
          city
          state
          postalCode
          __typename
        }
        phone
        createdAt
        updatedAt
        advisors {
          items {
            id
            companyName
            companyUrl
            companyLogo {
              bucket
              region
              key
              __typename
            }
            companyAddress
            address {
              streetAddress1
              streetAddress2
              city
              state
              postalCode
              __typename
            }
            firstName
            lastName
            email
            credentials
            phone
            avatar {
              bucket
              region
              key
              __typename
            }
            createdAt
            updatedAt
            showAdvisorCard
            prospectCriteria
            prospects {
              items {
                id
                type
                sendReport
                firstName
                lastName
                email
                phone
                advisorId
                advisorNotified
                advisorVanityName
                isAnonymous
                lastScoreStep
                subscribed
                scoreSurveyAnswers
                scoreSurveyComplete
                scoreSurveyResults
                scoreSurveySource
                scoreAgreeTerms
                scoreContactComplete
                createdAt
                updatedAt
                origin
                qualified
                __typename
              }
              nextToken
              __typename
            }
            vanityNames {
              items {
                id
                createdAt
                vanityName
                advisorId
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            notificationEmails
            disclosure
            intelGroupId
            bypassCrm
            customReportCtaUrl
            videoUrl
            scoreReportTemplateId
            surveyQuestions
            collectContactPostSurvey
            organizationId
            organization {
              id
              name
              routePathName
              logo {
                bucket
                region
                key
                __typename
              }
              active
              accessCode
              url
              address {
                streetAddress1
                streetAddress2
                city
                state
                postalCode
                __typename
              }
              phone
              createdAt
              updatedAt
              advisors {
                nextToken
                __typename
              }
              resources {
                nextToken
                __typename
              }
              promos {
                nextToken
                __typename
              }
              __typename
            }
            acceptRegistrationTerms
            promo_code
            payment_method
            stripe
            __typename
          }
          nextToken
          __typename
        }
        resources {
          items {
            id
            createdAt
            updatedAt
            organizationId
            displayOrder
            title
            subTitle
            mediaType
            s3Media {
              bucket
              region
              key
              __typename
            }
            mediaThumbnail {
              bucket
              region
              key
              __typename
            }
            urlTarget
            videoId
            organizationsToExclude
            __typename
          }
          nextToken
          __typename
        }
        promos {
          items {
            promoCode
            organizationId
            createdAt
            updatedAt
            description
            active
            duration
            endDate
            product_id
            discount
            price_id
            __typename
          }
          nextToken
          __typename
        }
        __typename
      }
      acceptRegistrationTerms
      promo_code
      payment_method
      stripe
      __typename
    }
  }
`;
export const onDeleteAdvisor = /* GraphQL */ `
  subscription OnDeleteAdvisor(
    $filter: ModelSubscriptionAdvisorFilterInput
    $id: String
  ) {
    onDeleteAdvisor(filter: $filter, id: $id) {
      id
      companyName
      companyUrl
      companyLogo {
        bucket
        region
        key
        __typename
      }
      companyAddress
      address {
        streetAddress1
        streetAddress2
        city
        state
        postalCode
        __typename
      }
      firstName
      lastName
      email
      credentials
      phone
      avatar {
        bucket
        region
        key
        __typename
      }
      createdAt
      updatedAt
      showAdvisorCard
      prospectCriteria
      prospects {
        items {
          id
          type
          sendReport
          firstName
          lastName
          email
          phone
          address {
            streetAddress1
            streetAddress2
            city
            state
            postalCode
            __typename
          }
          advisorId
          advisor {
            id
            companyName
            companyUrl
            companyLogo {
              bucket
              region
              key
              __typename
            }
            companyAddress
            address {
              streetAddress1
              streetAddress2
              city
              state
              postalCode
              __typename
            }
            firstName
            lastName
            email
            credentials
            phone
            avatar {
              bucket
              region
              key
              __typename
            }
            createdAt
            updatedAt
            showAdvisorCard
            prospectCriteria
            prospects {
              items {
                id
                type
                sendReport
                firstName
                lastName
                email
                phone
                advisorId
                advisorNotified
                advisorVanityName
                isAnonymous
                lastScoreStep
                subscribed
                scoreSurveyAnswers
                scoreSurveyComplete
                scoreSurveyResults
                scoreSurveySource
                scoreAgreeTerms
                scoreContactComplete
                createdAt
                updatedAt
                origin
                qualified
                __typename
              }
              nextToken
              __typename
            }
            vanityNames {
              items {
                id
                createdAt
                vanityName
                advisorId
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            notificationEmails
            disclosure
            intelGroupId
            bypassCrm
            customReportCtaUrl
            videoUrl
            scoreReportTemplateId
            surveyQuestions
            collectContactPostSurvey
            organizationId
            organization {
              id
              name
              routePathName
              logo {
                bucket
                region
                key
                __typename
              }
              active
              accessCode
              url
              address {
                streetAddress1
                streetAddress2
                city
                state
                postalCode
                __typename
              }
              phone
              createdAt
              updatedAt
              advisors {
                nextToken
                __typename
              }
              resources {
                nextToken
                __typename
              }
              promos {
                nextToken
                __typename
              }
              __typename
            }
            acceptRegistrationTerms
            promo_code
            payment_method
            stripe
            __typename
          }
          advisorNotified
          advisorVanityName
          isAnonymous
          lastScoreStep
          subscribed
          scoreSurveyAnswers
          scoreSurveyComplete
          scoreSurveyResults
          scoreSurveySource
          scoreAgreeTerms
          scoreContactComplete
          createdAt
          updatedAt
          origin
          qualified
          ctaRequests {
            id
            name
            createdAt
            updatedAt
            source
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      vanityNames {
        items {
          id
          createdAt
          vanityName
          advisorId
          advisor {
            id
            companyName
            companyUrl
            companyLogo {
              bucket
              region
              key
              __typename
            }
            companyAddress
            address {
              streetAddress1
              streetAddress2
              city
              state
              postalCode
              __typename
            }
            firstName
            lastName
            email
            credentials
            phone
            avatar {
              bucket
              region
              key
              __typename
            }
            createdAt
            updatedAt
            showAdvisorCard
            prospectCriteria
            prospects {
              items {
                id
                type
                sendReport
                firstName
                lastName
                email
                phone
                advisorId
                advisorNotified
                advisorVanityName
                isAnonymous
                lastScoreStep
                subscribed
                scoreSurveyAnswers
                scoreSurveyComplete
                scoreSurveyResults
                scoreSurveySource
                scoreAgreeTerms
                scoreContactComplete
                createdAt
                updatedAt
                origin
                qualified
                __typename
              }
              nextToken
              __typename
            }
            vanityNames {
              items {
                id
                createdAt
                vanityName
                advisorId
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            notificationEmails
            disclosure
            intelGroupId
            bypassCrm
            customReportCtaUrl
            videoUrl
            scoreReportTemplateId
            surveyQuestions
            collectContactPostSurvey
            organizationId
            organization {
              id
              name
              routePathName
              logo {
                bucket
                region
                key
                __typename
              }
              active
              accessCode
              url
              address {
                streetAddress1
                streetAddress2
                city
                state
                postalCode
                __typename
              }
              phone
              createdAt
              updatedAt
              advisors {
                nextToken
                __typename
              }
              resources {
                nextToken
                __typename
              }
              promos {
                nextToken
                __typename
              }
              __typename
            }
            acceptRegistrationTerms
            promo_code
            payment_method
            stripe
            __typename
          }
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      notificationEmails
      disclosure
      intelGroupId
      bypassCrm
      customReportCtaUrl
      videoUrl
      scoreReportTemplateId
      surveyQuestions
      collectContactPostSurvey
      organizationId
      organization {
        id
        name
        routePathName
        logo {
          bucket
          region
          key
          __typename
        }
        active
        accessCode
        url
        address {
          streetAddress1
          streetAddress2
          city
          state
          postalCode
          __typename
        }
        phone
        createdAt
        updatedAt
        advisors {
          items {
            id
            companyName
            companyUrl
            companyLogo {
              bucket
              region
              key
              __typename
            }
            companyAddress
            address {
              streetAddress1
              streetAddress2
              city
              state
              postalCode
              __typename
            }
            firstName
            lastName
            email
            credentials
            phone
            avatar {
              bucket
              region
              key
              __typename
            }
            createdAt
            updatedAt
            showAdvisorCard
            prospectCriteria
            prospects {
              items {
                id
                type
                sendReport
                firstName
                lastName
                email
                phone
                advisorId
                advisorNotified
                advisorVanityName
                isAnonymous
                lastScoreStep
                subscribed
                scoreSurveyAnswers
                scoreSurveyComplete
                scoreSurveyResults
                scoreSurveySource
                scoreAgreeTerms
                scoreContactComplete
                createdAt
                updatedAt
                origin
                qualified
                __typename
              }
              nextToken
              __typename
            }
            vanityNames {
              items {
                id
                createdAt
                vanityName
                advisorId
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            notificationEmails
            disclosure
            intelGroupId
            bypassCrm
            customReportCtaUrl
            videoUrl
            scoreReportTemplateId
            surveyQuestions
            collectContactPostSurvey
            organizationId
            organization {
              id
              name
              routePathName
              logo {
                bucket
                region
                key
                __typename
              }
              active
              accessCode
              url
              address {
                streetAddress1
                streetAddress2
                city
                state
                postalCode
                __typename
              }
              phone
              createdAt
              updatedAt
              advisors {
                nextToken
                __typename
              }
              resources {
                nextToken
                __typename
              }
              promos {
                nextToken
                __typename
              }
              __typename
            }
            acceptRegistrationTerms
            promo_code
            payment_method
            stripe
            __typename
          }
          nextToken
          __typename
        }
        resources {
          items {
            id
            createdAt
            updatedAt
            organizationId
            displayOrder
            title
            subTitle
            mediaType
            s3Media {
              bucket
              region
              key
              __typename
            }
            mediaThumbnail {
              bucket
              region
              key
              __typename
            }
            urlTarget
            videoId
            organizationsToExclude
            __typename
          }
          nextToken
          __typename
        }
        promos {
          items {
            promoCode
            organizationId
            createdAt
            updatedAt
            description
            active
            duration
            endDate
            product_id
            discount
            price_id
            __typename
          }
          nextToken
          __typename
        }
        __typename
      }
      acceptRegistrationTerms
      promo_code
      payment_method
      stripe
      __typename
    }
  }
`;
export const onCreateAdvisorVanityName = /* GraphQL */ `
  subscription OnCreateAdvisorVanityName(
    $filter: ModelSubscriptionAdvisorVanityNameFilterInput
    $advisorId: String
  ) {
    onCreateAdvisorVanityName(filter: $filter, advisorId: $advisorId) {
      id
      createdAt
      vanityName
      advisorId
      advisor {
        id
        companyName
        companyUrl
        companyLogo {
          bucket
          region
          key
          __typename
        }
        companyAddress
        address {
          streetAddress1
          streetAddress2
          city
          state
          postalCode
          __typename
        }
        firstName
        lastName
        email
        credentials
        phone
        avatar {
          bucket
          region
          key
          __typename
        }
        createdAt
        updatedAt
        showAdvisorCard
        prospectCriteria
        prospects {
          items {
            id
            type
            sendReport
            firstName
            lastName
            email
            phone
            address {
              streetAddress1
              streetAddress2
              city
              state
              postalCode
              __typename
            }
            advisorId
            advisor {
              id
              companyName
              companyUrl
              companyLogo {
                bucket
                region
                key
                __typename
              }
              companyAddress
              address {
                streetAddress1
                streetAddress2
                city
                state
                postalCode
                __typename
              }
              firstName
              lastName
              email
              credentials
              phone
              avatar {
                bucket
                region
                key
                __typename
              }
              createdAt
              updatedAt
              showAdvisorCard
              prospectCriteria
              prospects {
                nextToken
                __typename
              }
              vanityNames {
                nextToken
                __typename
              }
              notificationEmails
              disclosure
              intelGroupId
              bypassCrm
              customReportCtaUrl
              videoUrl
              scoreReportTemplateId
              surveyQuestions
              collectContactPostSurvey
              organizationId
              organization {
                id
                name
                routePathName
                active
                accessCode
                url
                phone
                createdAt
                updatedAt
                __typename
              }
              acceptRegistrationTerms
              promo_code
              payment_method
              stripe
              __typename
            }
            advisorNotified
            advisorVanityName
            isAnonymous
            lastScoreStep
            subscribed
            scoreSurveyAnswers
            scoreSurveyComplete
            scoreSurveyResults
            scoreSurveySource
            scoreAgreeTerms
            scoreContactComplete
            createdAt
            updatedAt
            origin
            qualified
            ctaRequests {
              id
              name
              createdAt
              updatedAt
              source
              __typename
            }
            __typename
          }
          nextToken
          __typename
        }
        vanityNames {
          items {
            id
            createdAt
            vanityName
            advisorId
            advisor {
              id
              companyName
              companyUrl
              companyLogo {
                bucket
                region
                key
                __typename
              }
              companyAddress
              address {
                streetAddress1
                streetAddress2
                city
                state
                postalCode
                __typename
              }
              firstName
              lastName
              email
              credentials
              phone
              avatar {
                bucket
                region
                key
                __typename
              }
              createdAt
              updatedAt
              showAdvisorCard
              prospectCriteria
              prospects {
                nextToken
                __typename
              }
              vanityNames {
                nextToken
                __typename
              }
              notificationEmails
              disclosure
              intelGroupId
              bypassCrm
              customReportCtaUrl
              videoUrl
              scoreReportTemplateId
              surveyQuestions
              collectContactPostSurvey
              organizationId
              organization {
                id
                name
                routePathName
                active
                accessCode
                url
                phone
                createdAt
                updatedAt
                __typename
              }
              acceptRegistrationTerms
              promo_code
              payment_method
              stripe
              __typename
            }
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        notificationEmails
        disclosure
        intelGroupId
        bypassCrm
        customReportCtaUrl
        videoUrl
        scoreReportTemplateId
        surveyQuestions
        collectContactPostSurvey
        organizationId
        organization {
          id
          name
          routePathName
          logo {
            bucket
            region
            key
            __typename
          }
          active
          accessCode
          url
          address {
            streetAddress1
            streetAddress2
            city
            state
            postalCode
            __typename
          }
          phone
          createdAt
          updatedAt
          advisors {
            items {
              id
              companyName
              companyUrl
              companyLogo {
                bucket
                region
                key
                __typename
              }
              companyAddress
              address {
                streetAddress1
                streetAddress2
                city
                state
                postalCode
                __typename
              }
              firstName
              lastName
              email
              credentials
              phone
              avatar {
                bucket
                region
                key
                __typename
              }
              createdAt
              updatedAt
              showAdvisorCard
              prospectCriteria
              prospects {
                nextToken
                __typename
              }
              vanityNames {
                nextToken
                __typename
              }
              notificationEmails
              disclosure
              intelGroupId
              bypassCrm
              customReportCtaUrl
              videoUrl
              scoreReportTemplateId
              surveyQuestions
              collectContactPostSurvey
              organizationId
              organization {
                id
                name
                routePathName
                active
                accessCode
                url
                phone
                createdAt
                updatedAt
                __typename
              }
              acceptRegistrationTerms
              promo_code
              payment_method
              stripe
              __typename
            }
            nextToken
            __typename
          }
          resources {
            items {
              id
              createdAt
              updatedAt
              organizationId
              displayOrder
              title
              subTitle
              mediaType
              s3Media {
                bucket
                region
                key
                __typename
              }
              mediaThumbnail {
                bucket
                region
                key
                __typename
              }
              urlTarget
              videoId
              organizationsToExclude
              __typename
            }
            nextToken
            __typename
          }
          promos {
            items {
              promoCode
              organizationId
              createdAt
              updatedAt
              description
              active
              duration
              endDate
              product_id
              discount
              price_id
              __typename
            }
            nextToken
            __typename
          }
          __typename
        }
        acceptRegistrationTerms
        promo_code
        payment_method
        stripe
        __typename
      }
      updatedAt
      __typename
    }
  }
`;
export const onUpdateAdvisorVanityName = /* GraphQL */ `
  subscription OnUpdateAdvisorVanityName(
    $filter: ModelSubscriptionAdvisorVanityNameFilterInput
    $advisorId: String
  ) {
    onUpdateAdvisorVanityName(filter: $filter, advisorId: $advisorId) {
      id
      createdAt
      vanityName
      advisorId
      advisor {
        id
        companyName
        companyUrl
        companyLogo {
          bucket
          region
          key
          __typename
        }
        companyAddress
        address {
          streetAddress1
          streetAddress2
          city
          state
          postalCode
          __typename
        }
        firstName
        lastName
        email
        credentials
        phone
        avatar {
          bucket
          region
          key
          __typename
        }
        createdAt
        updatedAt
        showAdvisorCard
        prospectCriteria
        prospects {
          items {
            id
            type
            sendReport
            firstName
            lastName
            email
            phone
            address {
              streetAddress1
              streetAddress2
              city
              state
              postalCode
              __typename
            }
            advisorId
            advisor {
              id
              companyName
              companyUrl
              companyLogo {
                bucket
                region
                key
                __typename
              }
              companyAddress
              address {
                streetAddress1
                streetAddress2
                city
                state
                postalCode
                __typename
              }
              firstName
              lastName
              email
              credentials
              phone
              avatar {
                bucket
                region
                key
                __typename
              }
              createdAt
              updatedAt
              showAdvisorCard
              prospectCriteria
              prospects {
                nextToken
                __typename
              }
              vanityNames {
                nextToken
                __typename
              }
              notificationEmails
              disclosure
              intelGroupId
              bypassCrm
              customReportCtaUrl
              videoUrl
              scoreReportTemplateId
              surveyQuestions
              collectContactPostSurvey
              organizationId
              organization {
                id
                name
                routePathName
                active
                accessCode
                url
                phone
                createdAt
                updatedAt
                __typename
              }
              acceptRegistrationTerms
              promo_code
              payment_method
              stripe
              __typename
            }
            advisorNotified
            advisorVanityName
            isAnonymous
            lastScoreStep
            subscribed
            scoreSurveyAnswers
            scoreSurveyComplete
            scoreSurveyResults
            scoreSurveySource
            scoreAgreeTerms
            scoreContactComplete
            createdAt
            updatedAt
            origin
            qualified
            ctaRequests {
              id
              name
              createdAt
              updatedAt
              source
              __typename
            }
            __typename
          }
          nextToken
          __typename
        }
        vanityNames {
          items {
            id
            createdAt
            vanityName
            advisorId
            advisor {
              id
              companyName
              companyUrl
              companyLogo {
                bucket
                region
                key
                __typename
              }
              companyAddress
              address {
                streetAddress1
                streetAddress2
                city
                state
                postalCode
                __typename
              }
              firstName
              lastName
              email
              credentials
              phone
              avatar {
                bucket
                region
                key
                __typename
              }
              createdAt
              updatedAt
              showAdvisorCard
              prospectCriteria
              prospects {
                nextToken
                __typename
              }
              vanityNames {
                nextToken
                __typename
              }
              notificationEmails
              disclosure
              intelGroupId
              bypassCrm
              customReportCtaUrl
              videoUrl
              scoreReportTemplateId
              surveyQuestions
              collectContactPostSurvey
              organizationId
              organization {
                id
                name
                routePathName
                active
                accessCode
                url
                phone
                createdAt
                updatedAt
                __typename
              }
              acceptRegistrationTerms
              promo_code
              payment_method
              stripe
              __typename
            }
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        notificationEmails
        disclosure
        intelGroupId
        bypassCrm
        customReportCtaUrl
        videoUrl
        scoreReportTemplateId
        surveyQuestions
        collectContactPostSurvey
        organizationId
        organization {
          id
          name
          routePathName
          logo {
            bucket
            region
            key
            __typename
          }
          active
          accessCode
          url
          address {
            streetAddress1
            streetAddress2
            city
            state
            postalCode
            __typename
          }
          phone
          createdAt
          updatedAt
          advisors {
            items {
              id
              companyName
              companyUrl
              companyLogo {
                bucket
                region
                key
                __typename
              }
              companyAddress
              address {
                streetAddress1
                streetAddress2
                city
                state
                postalCode
                __typename
              }
              firstName
              lastName
              email
              credentials
              phone
              avatar {
                bucket
                region
                key
                __typename
              }
              createdAt
              updatedAt
              showAdvisorCard
              prospectCriteria
              prospects {
                nextToken
                __typename
              }
              vanityNames {
                nextToken
                __typename
              }
              notificationEmails
              disclosure
              intelGroupId
              bypassCrm
              customReportCtaUrl
              videoUrl
              scoreReportTemplateId
              surveyQuestions
              collectContactPostSurvey
              organizationId
              organization {
                id
                name
                routePathName
                active
                accessCode
                url
                phone
                createdAt
                updatedAt
                __typename
              }
              acceptRegistrationTerms
              promo_code
              payment_method
              stripe
              __typename
            }
            nextToken
            __typename
          }
          resources {
            items {
              id
              createdAt
              updatedAt
              organizationId
              displayOrder
              title
              subTitle
              mediaType
              s3Media {
                bucket
                region
                key
                __typename
              }
              mediaThumbnail {
                bucket
                region
                key
                __typename
              }
              urlTarget
              videoId
              organizationsToExclude
              __typename
            }
            nextToken
            __typename
          }
          promos {
            items {
              promoCode
              organizationId
              createdAt
              updatedAt
              description
              active
              duration
              endDate
              product_id
              discount
              price_id
              __typename
            }
            nextToken
            __typename
          }
          __typename
        }
        acceptRegistrationTerms
        promo_code
        payment_method
        stripe
        __typename
      }
      updatedAt
      __typename
    }
  }
`;
export const onDeleteAdvisorVanityName = /* GraphQL */ `
  subscription OnDeleteAdvisorVanityName(
    $filter: ModelSubscriptionAdvisorVanityNameFilterInput
    $advisorId: String
  ) {
    onDeleteAdvisorVanityName(filter: $filter, advisorId: $advisorId) {
      id
      createdAt
      vanityName
      advisorId
      advisor {
        id
        companyName
        companyUrl
        companyLogo {
          bucket
          region
          key
          __typename
        }
        companyAddress
        address {
          streetAddress1
          streetAddress2
          city
          state
          postalCode
          __typename
        }
        firstName
        lastName
        email
        credentials
        phone
        avatar {
          bucket
          region
          key
          __typename
        }
        createdAt
        updatedAt
        showAdvisorCard
        prospectCriteria
        prospects {
          items {
            id
            type
            sendReport
            firstName
            lastName
            email
            phone
            address {
              streetAddress1
              streetAddress2
              city
              state
              postalCode
              __typename
            }
            advisorId
            advisor {
              id
              companyName
              companyUrl
              companyLogo {
                bucket
                region
                key
                __typename
              }
              companyAddress
              address {
                streetAddress1
                streetAddress2
                city
                state
                postalCode
                __typename
              }
              firstName
              lastName
              email
              credentials
              phone
              avatar {
                bucket
                region
                key
                __typename
              }
              createdAt
              updatedAt
              showAdvisorCard
              prospectCriteria
              prospects {
                nextToken
                __typename
              }
              vanityNames {
                nextToken
                __typename
              }
              notificationEmails
              disclosure
              intelGroupId
              bypassCrm
              customReportCtaUrl
              videoUrl
              scoreReportTemplateId
              surveyQuestions
              collectContactPostSurvey
              organizationId
              organization {
                id
                name
                routePathName
                active
                accessCode
                url
                phone
                createdAt
                updatedAt
                __typename
              }
              acceptRegistrationTerms
              promo_code
              payment_method
              stripe
              __typename
            }
            advisorNotified
            advisorVanityName
            isAnonymous
            lastScoreStep
            subscribed
            scoreSurveyAnswers
            scoreSurveyComplete
            scoreSurveyResults
            scoreSurveySource
            scoreAgreeTerms
            scoreContactComplete
            createdAt
            updatedAt
            origin
            qualified
            ctaRequests {
              id
              name
              createdAt
              updatedAt
              source
              __typename
            }
            __typename
          }
          nextToken
          __typename
        }
        vanityNames {
          items {
            id
            createdAt
            vanityName
            advisorId
            advisor {
              id
              companyName
              companyUrl
              companyLogo {
                bucket
                region
                key
                __typename
              }
              companyAddress
              address {
                streetAddress1
                streetAddress2
                city
                state
                postalCode
                __typename
              }
              firstName
              lastName
              email
              credentials
              phone
              avatar {
                bucket
                region
                key
                __typename
              }
              createdAt
              updatedAt
              showAdvisorCard
              prospectCriteria
              prospects {
                nextToken
                __typename
              }
              vanityNames {
                nextToken
                __typename
              }
              notificationEmails
              disclosure
              intelGroupId
              bypassCrm
              customReportCtaUrl
              videoUrl
              scoreReportTemplateId
              surveyQuestions
              collectContactPostSurvey
              organizationId
              organization {
                id
                name
                routePathName
                active
                accessCode
                url
                phone
                createdAt
                updatedAt
                __typename
              }
              acceptRegistrationTerms
              promo_code
              payment_method
              stripe
              __typename
            }
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        notificationEmails
        disclosure
        intelGroupId
        bypassCrm
        customReportCtaUrl
        videoUrl
        scoreReportTemplateId
        surveyQuestions
        collectContactPostSurvey
        organizationId
        organization {
          id
          name
          routePathName
          logo {
            bucket
            region
            key
            __typename
          }
          active
          accessCode
          url
          address {
            streetAddress1
            streetAddress2
            city
            state
            postalCode
            __typename
          }
          phone
          createdAt
          updatedAt
          advisors {
            items {
              id
              companyName
              companyUrl
              companyLogo {
                bucket
                region
                key
                __typename
              }
              companyAddress
              address {
                streetAddress1
                streetAddress2
                city
                state
                postalCode
                __typename
              }
              firstName
              lastName
              email
              credentials
              phone
              avatar {
                bucket
                region
                key
                __typename
              }
              createdAt
              updatedAt
              showAdvisorCard
              prospectCriteria
              prospects {
                nextToken
                __typename
              }
              vanityNames {
                nextToken
                __typename
              }
              notificationEmails
              disclosure
              intelGroupId
              bypassCrm
              customReportCtaUrl
              videoUrl
              scoreReportTemplateId
              surveyQuestions
              collectContactPostSurvey
              organizationId
              organization {
                id
                name
                routePathName
                active
                accessCode
                url
                phone
                createdAt
                updatedAt
                __typename
              }
              acceptRegistrationTerms
              promo_code
              payment_method
              stripe
              __typename
            }
            nextToken
            __typename
          }
          resources {
            items {
              id
              createdAt
              updatedAt
              organizationId
              displayOrder
              title
              subTitle
              mediaType
              s3Media {
                bucket
                region
                key
                __typename
              }
              mediaThumbnail {
                bucket
                region
                key
                __typename
              }
              urlTarget
              videoId
              organizationsToExclude
              __typename
            }
            nextToken
            __typename
          }
          promos {
            items {
              promoCode
              organizationId
              createdAt
              updatedAt
              description
              active
              duration
              endDate
              product_id
              discount
              price_id
              __typename
            }
            nextToken
            __typename
          }
          __typename
        }
        acceptRegistrationTerms
        promo_code
        payment_method
        stripe
        __typename
      }
      updatedAt
      __typename
    }
  }
`;
export const onCreateProspect = /* GraphQL */ `
  subscription OnCreateProspect($filter: ModelSubscriptionProspectFilterInput) {
    onCreateProspect(filter: $filter) {
      id
      type
      sendReport
      firstName
      lastName
      email
      phone
      address {
        streetAddress1
        streetAddress2
        city
        state
        postalCode
        __typename
      }
      advisorId
      advisor {
        id
        companyName
        companyUrl
        companyLogo {
          bucket
          region
          key
          __typename
        }
        companyAddress
        address {
          streetAddress1
          streetAddress2
          city
          state
          postalCode
          __typename
        }
        firstName
        lastName
        email
        credentials
        phone
        avatar {
          bucket
          region
          key
          __typename
        }
        createdAt
        updatedAt
        showAdvisorCard
        prospectCriteria
        prospects {
          items {
            id
            type
            sendReport
            firstName
            lastName
            email
            phone
            address {
              streetAddress1
              streetAddress2
              city
              state
              postalCode
              __typename
            }
            advisorId
            advisor {
              id
              companyName
              companyUrl
              companyLogo {
                bucket
                region
                key
                __typename
              }
              companyAddress
              address {
                streetAddress1
                streetAddress2
                city
                state
                postalCode
                __typename
              }
              firstName
              lastName
              email
              credentials
              phone
              avatar {
                bucket
                region
                key
                __typename
              }
              createdAt
              updatedAt
              showAdvisorCard
              prospectCriteria
              prospects {
                nextToken
                __typename
              }
              vanityNames {
                nextToken
                __typename
              }
              notificationEmails
              disclosure
              intelGroupId
              bypassCrm
              customReportCtaUrl
              videoUrl
              scoreReportTemplateId
              surveyQuestions
              collectContactPostSurvey
              organizationId
              organization {
                id
                name
                routePathName
                active
                accessCode
                url
                phone
                createdAt
                updatedAt
                __typename
              }
              acceptRegistrationTerms
              promo_code
              payment_method
              stripe
              __typename
            }
            advisorNotified
            advisorVanityName
            isAnonymous
            lastScoreStep
            subscribed
            scoreSurveyAnswers
            scoreSurveyComplete
            scoreSurveyResults
            scoreSurveySource
            scoreAgreeTerms
            scoreContactComplete
            createdAt
            updatedAt
            origin
            qualified
            ctaRequests {
              id
              name
              createdAt
              updatedAt
              source
              __typename
            }
            __typename
          }
          nextToken
          __typename
        }
        vanityNames {
          items {
            id
            createdAt
            vanityName
            advisorId
            advisor {
              id
              companyName
              companyUrl
              companyLogo {
                bucket
                region
                key
                __typename
              }
              companyAddress
              address {
                streetAddress1
                streetAddress2
                city
                state
                postalCode
                __typename
              }
              firstName
              lastName
              email
              credentials
              phone
              avatar {
                bucket
                region
                key
                __typename
              }
              createdAt
              updatedAt
              showAdvisorCard
              prospectCriteria
              prospects {
                nextToken
                __typename
              }
              vanityNames {
                nextToken
                __typename
              }
              notificationEmails
              disclosure
              intelGroupId
              bypassCrm
              customReportCtaUrl
              videoUrl
              scoreReportTemplateId
              surveyQuestions
              collectContactPostSurvey
              organizationId
              organization {
                id
                name
                routePathName
                active
                accessCode
                url
                phone
                createdAt
                updatedAt
                __typename
              }
              acceptRegistrationTerms
              promo_code
              payment_method
              stripe
              __typename
            }
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        notificationEmails
        disclosure
        intelGroupId
        bypassCrm
        customReportCtaUrl
        videoUrl
        scoreReportTemplateId
        surveyQuestions
        collectContactPostSurvey
        organizationId
        organization {
          id
          name
          routePathName
          logo {
            bucket
            region
            key
            __typename
          }
          active
          accessCode
          url
          address {
            streetAddress1
            streetAddress2
            city
            state
            postalCode
            __typename
          }
          phone
          createdAt
          updatedAt
          advisors {
            items {
              id
              companyName
              companyUrl
              companyLogo {
                bucket
                region
                key
                __typename
              }
              companyAddress
              address {
                streetAddress1
                streetAddress2
                city
                state
                postalCode
                __typename
              }
              firstName
              lastName
              email
              credentials
              phone
              avatar {
                bucket
                region
                key
                __typename
              }
              createdAt
              updatedAt
              showAdvisorCard
              prospectCriteria
              prospects {
                nextToken
                __typename
              }
              vanityNames {
                nextToken
                __typename
              }
              notificationEmails
              disclosure
              intelGroupId
              bypassCrm
              customReportCtaUrl
              videoUrl
              scoreReportTemplateId
              surveyQuestions
              collectContactPostSurvey
              organizationId
              organization {
                id
                name
                routePathName
                active
                accessCode
                url
                phone
                createdAt
                updatedAt
                __typename
              }
              acceptRegistrationTerms
              promo_code
              payment_method
              stripe
              __typename
            }
            nextToken
            __typename
          }
          resources {
            items {
              id
              createdAt
              updatedAt
              organizationId
              displayOrder
              title
              subTitle
              mediaType
              s3Media {
                bucket
                region
                key
                __typename
              }
              mediaThumbnail {
                bucket
                region
                key
                __typename
              }
              urlTarget
              videoId
              organizationsToExclude
              __typename
            }
            nextToken
            __typename
          }
          promos {
            items {
              promoCode
              organizationId
              createdAt
              updatedAt
              description
              active
              duration
              endDate
              product_id
              discount
              price_id
              __typename
            }
            nextToken
            __typename
          }
          __typename
        }
        acceptRegistrationTerms
        promo_code
        payment_method
        stripe
        __typename
      }
      advisorNotified
      advisorVanityName
      isAnonymous
      lastScoreStep
      subscribed
      scoreSurveyAnswers
      scoreSurveyComplete
      scoreSurveyResults
      scoreSurveySource
      scoreAgreeTerms
      scoreContactComplete
      createdAt
      updatedAt
      origin
      qualified
      ctaRequests {
        id
        name
        createdAt
        updatedAt
        source
        __typename
      }
      __typename
    }
  }
`;
export const onUpdateProspect = /* GraphQL */ `
  subscription OnUpdateProspect($filter: ModelSubscriptionProspectFilterInput) {
    onUpdateProspect(filter: $filter) {
      id
      type
      sendReport
      firstName
      lastName
      email
      phone
      address {
        streetAddress1
        streetAddress2
        city
        state
        postalCode
        __typename
      }
      advisorId
      advisor {
        id
        companyName
        companyUrl
        companyLogo {
          bucket
          region
          key
          __typename
        }
        companyAddress
        address {
          streetAddress1
          streetAddress2
          city
          state
          postalCode
          __typename
        }
        firstName
        lastName
        email
        credentials
        phone
        avatar {
          bucket
          region
          key
          __typename
        }
        createdAt
        updatedAt
        showAdvisorCard
        prospectCriteria
        prospects {
          items {
            id
            type
            sendReport
            firstName
            lastName
            email
            phone
            address {
              streetAddress1
              streetAddress2
              city
              state
              postalCode
              __typename
            }
            advisorId
            advisor {
              id
              companyName
              companyUrl
              companyLogo {
                bucket
                region
                key
                __typename
              }
              companyAddress
              address {
                streetAddress1
                streetAddress2
                city
                state
                postalCode
                __typename
              }
              firstName
              lastName
              email
              credentials
              phone
              avatar {
                bucket
                region
                key
                __typename
              }
              createdAt
              updatedAt
              showAdvisorCard
              prospectCriteria
              prospects {
                nextToken
                __typename
              }
              vanityNames {
                nextToken
                __typename
              }
              notificationEmails
              disclosure
              intelGroupId
              bypassCrm
              customReportCtaUrl
              videoUrl
              scoreReportTemplateId
              surveyQuestions
              collectContactPostSurvey
              organizationId
              organization {
                id
                name
                routePathName
                active
                accessCode
                url
                phone
                createdAt
                updatedAt
                __typename
              }
              acceptRegistrationTerms
              promo_code
              payment_method
              stripe
              __typename
            }
            advisorNotified
            advisorVanityName
            isAnonymous
            lastScoreStep
            subscribed
            scoreSurveyAnswers
            scoreSurveyComplete
            scoreSurveyResults
            scoreSurveySource
            scoreAgreeTerms
            scoreContactComplete
            createdAt
            updatedAt
            origin
            qualified
            ctaRequests {
              id
              name
              createdAt
              updatedAt
              source
              __typename
            }
            __typename
          }
          nextToken
          __typename
        }
        vanityNames {
          items {
            id
            createdAt
            vanityName
            advisorId
            advisor {
              id
              companyName
              companyUrl
              companyLogo {
                bucket
                region
                key
                __typename
              }
              companyAddress
              address {
                streetAddress1
                streetAddress2
                city
                state
                postalCode
                __typename
              }
              firstName
              lastName
              email
              credentials
              phone
              avatar {
                bucket
                region
                key
                __typename
              }
              createdAt
              updatedAt
              showAdvisorCard
              prospectCriteria
              prospects {
                nextToken
                __typename
              }
              vanityNames {
                nextToken
                __typename
              }
              notificationEmails
              disclosure
              intelGroupId
              bypassCrm
              customReportCtaUrl
              videoUrl
              scoreReportTemplateId
              surveyQuestions
              collectContactPostSurvey
              organizationId
              organization {
                id
                name
                routePathName
                active
                accessCode
                url
                phone
                createdAt
                updatedAt
                __typename
              }
              acceptRegistrationTerms
              promo_code
              payment_method
              stripe
              __typename
            }
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        notificationEmails
        disclosure
        intelGroupId
        bypassCrm
        customReportCtaUrl
        videoUrl
        scoreReportTemplateId
        surveyQuestions
        collectContactPostSurvey
        organizationId
        organization {
          id
          name
          routePathName
          logo {
            bucket
            region
            key
            __typename
          }
          active
          accessCode
          url
          address {
            streetAddress1
            streetAddress2
            city
            state
            postalCode
            __typename
          }
          phone
          createdAt
          updatedAt
          advisors {
            items {
              id
              companyName
              companyUrl
              companyLogo {
                bucket
                region
                key
                __typename
              }
              companyAddress
              address {
                streetAddress1
                streetAddress2
                city
                state
                postalCode
                __typename
              }
              firstName
              lastName
              email
              credentials
              phone
              avatar {
                bucket
                region
                key
                __typename
              }
              createdAt
              updatedAt
              showAdvisorCard
              prospectCriteria
              prospects {
                nextToken
                __typename
              }
              vanityNames {
                nextToken
                __typename
              }
              notificationEmails
              disclosure
              intelGroupId
              bypassCrm
              customReportCtaUrl
              videoUrl
              scoreReportTemplateId
              surveyQuestions
              collectContactPostSurvey
              organizationId
              organization {
                id
                name
                routePathName
                active
                accessCode
                url
                phone
                createdAt
                updatedAt
                __typename
              }
              acceptRegistrationTerms
              promo_code
              payment_method
              stripe
              __typename
            }
            nextToken
            __typename
          }
          resources {
            items {
              id
              createdAt
              updatedAt
              organizationId
              displayOrder
              title
              subTitle
              mediaType
              s3Media {
                bucket
                region
                key
                __typename
              }
              mediaThumbnail {
                bucket
                region
                key
                __typename
              }
              urlTarget
              videoId
              organizationsToExclude
              __typename
            }
            nextToken
            __typename
          }
          promos {
            items {
              promoCode
              organizationId
              createdAt
              updatedAt
              description
              active
              duration
              endDate
              product_id
              discount
              price_id
              __typename
            }
            nextToken
            __typename
          }
          __typename
        }
        acceptRegistrationTerms
        promo_code
        payment_method
        stripe
        __typename
      }
      advisorNotified
      advisorVanityName
      isAnonymous
      lastScoreStep
      subscribed
      scoreSurveyAnswers
      scoreSurveyComplete
      scoreSurveyResults
      scoreSurveySource
      scoreAgreeTerms
      scoreContactComplete
      createdAt
      updatedAt
      origin
      qualified
      ctaRequests {
        id
        name
        createdAt
        updatedAt
        source
        __typename
      }
      __typename
    }
  }
`;
export const onDeleteProspect = /* GraphQL */ `
  subscription OnDeleteProspect($filter: ModelSubscriptionProspectFilterInput) {
    onDeleteProspect(filter: $filter) {
      id
      type
      sendReport
      firstName
      lastName
      email
      phone
      address {
        streetAddress1
        streetAddress2
        city
        state
        postalCode
        __typename
      }
      advisorId
      advisor {
        id
        companyName
        companyUrl
        companyLogo {
          bucket
          region
          key
          __typename
        }
        companyAddress
        address {
          streetAddress1
          streetAddress2
          city
          state
          postalCode
          __typename
        }
        firstName
        lastName
        email
        credentials
        phone
        avatar {
          bucket
          region
          key
          __typename
        }
        createdAt
        updatedAt
        showAdvisorCard
        prospectCriteria
        prospects {
          items {
            id
            type
            sendReport
            firstName
            lastName
            email
            phone
            address {
              streetAddress1
              streetAddress2
              city
              state
              postalCode
              __typename
            }
            advisorId
            advisor {
              id
              companyName
              companyUrl
              companyLogo {
                bucket
                region
                key
                __typename
              }
              companyAddress
              address {
                streetAddress1
                streetAddress2
                city
                state
                postalCode
                __typename
              }
              firstName
              lastName
              email
              credentials
              phone
              avatar {
                bucket
                region
                key
                __typename
              }
              createdAt
              updatedAt
              showAdvisorCard
              prospectCriteria
              prospects {
                nextToken
                __typename
              }
              vanityNames {
                nextToken
                __typename
              }
              notificationEmails
              disclosure
              intelGroupId
              bypassCrm
              customReportCtaUrl
              videoUrl
              scoreReportTemplateId
              surveyQuestions
              collectContactPostSurvey
              organizationId
              organization {
                id
                name
                routePathName
                active
                accessCode
                url
                phone
                createdAt
                updatedAt
                __typename
              }
              acceptRegistrationTerms
              promo_code
              payment_method
              stripe
              __typename
            }
            advisorNotified
            advisorVanityName
            isAnonymous
            lastScoreStep
            subscribed
            scoreSurveyAnswers
            scoreSurveyComplete
            scoreSurveyResults
            scoreSurveySource
            scoreAgreeTerms
            scoreContactComplete
            createdAt
            updatedAt
            origin
            qualified
            ctaRequests {
              id
              name
              createdAt
              updatedAt
              source
              __typename
            }
            __typename
          }
          nextToken
          __typename
        }
        vanityNames {
          items {
            id
            createdAt
            vanityName
            advisorId
            advisor {
              id
              companyName
              companyUrl
              companyLogo {
                bucket
                region
                key
                __typename
              }
              companyAddress
              address {
                streetAddress1
                streetAddress2
                city
                state
                postalCode
                __typename
              }
              firstName
              lastName
              email
              credentials
              phone
              avatar {
                bucket
                region
                key
                __typename
              }
              createdAt
              updatedAt
              showAdvisorCard
              prospectCriteria
              prospects {
                nextToken
                __typename
              }
              vanityNames {
                nextToken
                __typename
              }
              notificationEmails
              disclosure
              intelGroupId
              bypassCrm
              customReportCtaUrl
              videoUrl
              scoreReportTemplateId
              surveyQuestions
              collectContactPostSurvey
              organizationId
              organization {
                id
                name
                routePathName
                active
                accessCode
                url
                phone
                createdAt
                updatedAt
                __typename
              }
              acceptRegistrationTerms
              promo_code
              payment_method
              stripe
              __typename
            }
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        notificationEmails
        disclosure
        intelGroupId
        bypassCrm
        customReportCtaUrl
        videoUrl
        scoreReportTemplateId
        surveyQuestions
        collectContactPostSurvey
        organizationId
        organization {
          id
          name
          routePathName
          logo {
            bucket
            region
            key
            __typename
          }
          active
          accessCode
          url
          address {
            streetAddress1
            streetAddress2
            city
            state
            postalCode
            __typename
          }
          phone
          createdAt
          updatedAt
          advisors {
            items {
              id
              companyName
              companyUrl
              companyLogo {
                bucket
                region
                key
                __typename
              }
              companyAddress
              address {
                streetAddress1
                streetAddress2
                city
                state
                postalCode
                __typename
              }
              firstName
              lastName
              email
              credentials
              phone
              avatar {
                bucket
                region
                key
                __typename
              }
              createdAt
              updatedAt
              showAdvisorCard
              prospectCriteria
              prospects {
                nextToken
                __typename
              }
              vanityNames {
                nextToken
                __typename
              }
              notificationEmails
              disclosure
              intelGroupId
              bypassCrm
              customReportCtaUrl
              videoUrl
              scoreReportTemplateId
              surveyQuestions
              collectContactPostSurvey
              organizationId
              organization {
                id
                name
                routePathName
                active
                accessCode
                url
                phone
                createdAt
                updatedAt
                __typename
              }
              acceptRegistrationTerms
              promo_code
              payment_method
              stripe
              __typename
            }
            nextToken
            __typename
          }
          resources {
            items {
              id
              createdAt
              updatedAt
              organizationId
              displayOrder
              title
              subTitle
              mediaType
              s3Media {
                bucket
                region
                key
                __typename
              }
              mediaThumbnail {
                bucket
                region
                key
                __typename
              }
              urlTarget
              videoId
              organizationsToExclude
              __typename
            }
            nextToken
            __typename
          }
          promos {
            items {
              promoCode
              organizationId
              createdAt
              updatedAt
              description
              active
              duration
              endDate
              product_id
              discount
              price_id
              __typename
            }
            nextToken
            __typename
          }
          __typename
        }
        acceptRegistrationTerms
        promo_code
        payment_method
        stripe
        __typename
      }
      advisorNotified
      advisorVanityName
      isAnonymous
      lastScoreStep
      subscribed
      scoreSurveyAnswers
      scoreSurveyComplete
      scoreSurveyResults
      scoreSurveySource
      scoreAgreeTerms
      scoreContactComplete
      createdAt
      updatedAt
      origin
      qualified
      ctaRequests {
        id
        name
        createdAt
        updatedAt
        source
        __typename
      }
      __typename
    }
  }
`;
export const onCreateReportTemplate = /* GraphQL */ `
  subscription OnCreateReportTemplate(
    $filter: ModelSubscriptionReportTemplateFilterInput
  ) {
    onCreateReportTemplate(filter: $filter) {
      id
      createdAt
      updatedAt
      template
      version
      name
      data
      current
      __typename
    }
  }
`;
export const onUpdateReportTemplate = /* GraphQL */ `
  subscription OnUpdateReportTemplate(
    $filter: ModelSubscriptionReportTemplateFilterInput
  ) {
    onUpdateReportTemplate(filter: $filter) {
      id
      createdAt
      updatedAt
      template
      version
      name
      data
      current
      __typename
    }
  }
`;
export const onDeleteReportTemplate = /* GraphQL */ `
  subscription OnDeleteReportTemplate(
    $filter: ModelSubscriptionReportTemplateFilterInput
  ) {
    onDeleteReportTemplate(filter: $filter) {
      id
      createdAt
      updatedAt
      template
      version
      name
      data
      current
      __typename
    }
  }
`;
